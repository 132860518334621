import React, { useEffect, useRef }  from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import BannerOne from "../components/BannerOne";
import BlogAreaOne from "../components/BlogAreaOne";
import CaseStudyAreaOne from "../components/CaseStudyAreaOne";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import PricingAreaOne from "../components/PricingAreaOne";
import ServiceAreaOne from "../components/ServiceAreaOne";
import TeamAreaOne from "../components/TeamAreaOne";
import WorkProcessOne from "../components/WorkProcessOne";
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'; // Import the LoadingBar component


const HomeOne = () => {
  const loadingBarRef = useRef(null); // Create a reference for the loading bar

  useEffect(() => {
    // Optional: Simulate a loading process when the Service component mounts
    loadingBarRef.current.continuousStart(); // Start the loading bar
    setTimeout(() => {
      loadingBarRef.current.complete(); // Complete the loading bar after a delay (simulating loading)
    }, 2000); // Change the delay time as needed
  }, []);
  return (
    <>
   <LoadingBar color="#1F5ABC" ref={loadingBarRef} />
   <Helmet>
      <title>Planet Solutions: Expert Graphic Design, Web Design, and Ecommerce Solutions
          </title>
            <meta name="description" content="Unlock your brand's potential with Planet Solutions! Expert in Graphic & Web Design, Ecommerce Solutions for a captivating online presence. Get started today! "/>
<link rel='canonical' href='/' />

</Helmet>


      {/* Navigation Bar */}
      <NavBar />

      {/* Banner One */}
      <BannerOne />

      {/* About Area One */}
      <AboutAreaOne />

      {/* Service Area One */}
      <ServiceAreaOne />

      {/* FAQ Area One */}
      <FaqAreaOne />

      {/* Case Study Area One */}
      <CaseStudyAreaOne />

      {/* Team Area One */}
      <TeamAreaOne />

      {/* Counter Area One */}
      <CounterAreaOne />

      {/* Contact Area One */}
      <ContactAreaOne />

      {/* Work Process One */}
      <WorkProcessOne />

      {/* Pricing Area One */}
      <PricingAreaOne />

      {/* Blog Area One */}
      <BlogAreaOne />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default HomeOne;
