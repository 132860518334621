import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import { cloudinary } from "../pages/cloudinaryConfig";
import { Image } from 'cloudinary-react';

export default class MultipleItems extends Component {

  render() {
    const settings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      swipeToSlide: true,
      pauseOnHover: true,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            centerMode: true, // Disable center mode
            variableWidth: false, // Disable variable width
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: false,
            centerMode: true, // Disable center mode
            variableWidth: true, // Disable variable width
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
      
            
          }
        }
      ]
            
    };
    return (
      <div className="container-fluid" >
        <Slider {...settings}>
        
            {/* <img src='assets/img/client/Ankara.avif'  alt='Logo design services' className="slider-area img-fluid" /> */}
            <Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/Ankara_x8edvi"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
      />
         
         <Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/Big_Bazaar_wufilx"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
      />
         
                  
         <Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/Deccan_Valley_cut5db"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
      />           
          <Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/Desi_Nai_fntmi8"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
        
      />           
        
         
        <Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/EBS_e0rrpz"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
      />           
        
        <Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/Empire_Palace_u78nnh"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
      />           
        
         
        <Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/Iconic_Kebabs_wm15ie"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
      />   

<Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/URban_Wala_w48ek2"
        className="slider-area img-fluid"
        alt="Logo design services"
        format="webp"
      /> 
         
       
        </Slider>
      </div>
    );
  }
}