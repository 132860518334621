import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceAreaOne = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/service?_embed&order=asc`
        );
        if (response.ok) {
          const data = await response.json();
          setServices(data);
        } else {
          console.error("Failed to fetch data from WordPress API");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  }, []);
  

  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
          <div className='section-title text-center'>
            <h4 className='sub-title'>OUR CREATIVE SERVICES</h4>
            <h2 className='title'>
              What We <span>Do</span>
            </h2>
          </div>
          <div className='row'>
          {services.slice(0, 3).map((data, index) => (
              <div className='col-lg-4 col-md-6' key={index} >
                <div className='single-service-inner text-center'>
                <div className='thumb'>
  {/* Display the custom field value for the icon */}
  <img
    src={data.acf && data.acf.service_icon ? data.acf.service_icon.url : ''} // Check if data.acf and data.acf.service_icon exist
    alt={data.acf && data.acf.service_icon ? data.acf.service_icon.alt : ''}
  />
</div>

                  <div className='details'>
                    <h3>
                      <Link to={`/service-details/${data.slug}`}>
                        {data.title.rendered}
                      </Link>
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: data.excerpt.rendered }}></p>

                    <Link
                      className='btn btn-border-base'
                      to={`/service-details/${data.slug}`}
                    >
                      See Details <FaPlus />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaOne;
