import React from "react";
import Slider from "../components/slider";

const TeamAreaOne = () => {
  return (
    <>
      {/*====================== team area start ======================*/}
      <div className='team-area bg-relative pd-top-90 pd-bottom-90'>
        <div className='container-fluid'>
          <div className='section-title text-center'>
            <h4 className='sub-title'>CLIENTS</h4>
            <h2 className='title'>
             Our <span>clients</span> 
            </h2>
          </div>  
          <Slider />      
        </div>
      </div>
   
      {/* ====================== team area end ======================*/}
    </>
  );
};



export default TeamAreaOne;

