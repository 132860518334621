import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles , faUsers , faPenNib , faRocket} from '@fortawesome/free-solid-svg-icons';
const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h3 className='sub-title'>How We Work</h3>
            <h2 className='title'>
              HOW <span>WE</span> WORK
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                <FontAwesomeIcon icon={faUsers} className="custom-icon" />
                </div>
                <div className='details'>
                  <p className='process-count'>Learn</p>
                  <h3 className='mb-3'>Client Proposal</h3>
                  <p className='content'>
                  Meet, Information Sharing, Proposal
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                <FontAwesomeIcon icon={faWandMagicSparkles} className="custom-icon" />
                </div>
                <div className='details'>
                  <p className='process-count'>Plan</p>
                  <h4 className='mb-3'>Research</h4>
                  <p className='content'>
                  Research, Schedule, Resource allocation
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                <FontAwesomeIcon icon={faPenNib} className="custom-icon"  />
                </div>
                <div className='details'>
                  <p className='process-count'>Design / Development</p>
                  <h5 className='mb-3'>Magic Comes Alive</h5>
                  <p className='content'>
                  Designing , Prototype, Development </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                <FontAwesomeIcon icon={faRocket} className="custom-icon" />
                </div>
                <div className='details'>
                  <p className='process-count'>Launch</p>
                  <h5 className='mb-3'>Ready For Launch</h5>
                  <p className='content'>
                  Final Assets Submission: Ready for Launch</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
