import React, { useState, useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";

import { Link, useParams } from "react-router-dom";

const ServiceDetailsArea = () => {
  const [post, setPost] = useState(null);
  const { slug } = useParams();
  const [acfImages, setAcfImages] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);

  useEffect(() => {
    async function fetchServiceCategories() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/service_category`
        );
        if (response.ok) {
          const data = await response.json();
          setServiceCategories(data);
        } else {
          console.error("Failed to fetch service categories from WordPress API");
        }
      } catch (error) {
        console.error("Error fetching service categories:", error);
      }
    }
  
    fetchServiceCategories();
  }, []);
  

  useEffect(() => {
    async function fetchPostBySlug() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/service?slug=${slug}&_embed`
        );
        if (response.ok) {
          const data = await response.json();
          // Check if data is an array and not empty
          if (Array.isArray(data) && data.length > 0) {
            setPost(data[0]); // Use the first result if multiple posts match the slug
          } else {
            console.error("Post not found for slug: " + slug);
          }
        } else {
          console.error("Failed to fetch post from WordPress API");
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    }

    async function fetchAcfImages() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/service_cards`
        );
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data) && data.length > 0) {
            setAcfImages(data); // Set the array of acf_images
          } else {
            console.error("acf_images not found");
          }
        } else {
          console.error("Failed to fetch acf_images from WordPress API");
        }
      } catch (error) {
        console.error("Error fetching acf_images:", error);
      }
    }

    fetchPostBySlug();
    fetchAcfImages();
  }, [slug]);
  
// Define the WordPress API endpoint URL
const apiUrl = `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/url-download`;

// Function to create list items from the API data
function createListItems(data) {
  const ul = document.getElementById('dynamicList');

  // Clear existing list items
  ul.innerHTML = '';

  // Check if the data is an array
  if (Array.isArray(data)) {
    data.forEach((item) => {
      // Extract title and URL from ACF fields
      const title = item.acf.title_1;
      const url = item.acf.company_profile;

      // Create list item and anchor element
      const li = document.createElement('li');
      const a = document.createElement('a');
      a.href = url;
      
      // Create a text node for the title
      const textNode = document.createTextNode(title);
      
      // Add the angle double right icon
      const icon = document.createElement('span');
      icon.className = 'FaAngleDoubleRight'; // Replace with the correct class name for your icon
      
      // Append the text node and icon to the anchor element
      a.appendChild(textNode);
      a.appendChild(icon);
      
      // Append the anchor element to the list item
      li.appendChild(a);
      
      // Append the list item to the unordered list
      ul.appendChild(li);
    });
  }
}

// Fetch data from the WordPress API
fetch(apiUrl)
  .then((response) => response.json())
  .then((data) => {
    // Call the function to create list items with the fetched data
    createListItems(data);
  })
  .catch((error) => {
    console.error('Error fetching data:', error);
  });


  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className="service-area pt-4 pd-bottom-90">
        <div className="container">
          {post ? (
            <div className="row" data-aos="fade-up"
            data-aos-duration="600">
                <div className="col-lg-8">
                <div className="blog-details-page-content">
                  <div className="single-blog-inner mb-0">
                    <div className="thumb">
                      <img
                        src={post._embedded["wp:featuredmedia"][0].source_url}
                        alt="img"
                        className="img-fluid"
                        style={{width:'100%'}}
                      />
                    </div>
                    <div className="details">
                    {/* <h4 style={{ color: 'var(--main-color)' }}>{post.title.rendered}</h4> */}

             
                 
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.content.rendered,
                        }}>
                          
                        </div>
                        <div className="row ">
                          <div className="col-12">
                        <a className='btn btn-base border-radius-5' href='/contact'>
                      Contact Now +
                    </a>
                    </div>
                    </div>
              
                    </div>
                  </div>
                  <section>
                    <div className="row p-4" >
                    <h3 className='widget-title pb-3'>
                    <FaArrowRight /> Exploring Additional Services?
                  </h3>
                       {acfImages.map((acfImage) => (
          <div className="col-lg-3 col-md-3 col-12 pb-3" key={acfImage.id}>
             <a href={acfImage.acf && acfImage.acf.acf_url ? acfImage.acf.acf_url : '#'}>
            <div className="card single-service-img">
            <div className="img-flow">
           
              <img
                src={acfImage.acf && acfImage.acf.acf_image ? acfImage.acf.acf_image.url : ''}
                alt={acfImage.acf && acfImage.acf.acf_image ? 'Image Alt Text' : ''}
                className="card-img-top img-fluid"
              />
             </div>
              <div className="card-body text-center">
            
                <h5 className="card-title">{acfImage.acf && acfImage.acf.acf_text ? acfImage.acf.acf_text : 'Default Title'}</h5>
              
              </div>
            </div>
            </a>
            
          </div>
        ))}
        </div>
        </section>
                </div>
           
              </div>
              <div className="col-lg-4 col-12">
                <div className="td-sidebar service-sidebar">
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> All Service lists
                  </h5>
                  <ul>
  {acfImages.map((acfImage, index) => (
    <li key={index}>
      <a href={acfImage?.acf?.acf_url || '#'}>
        {acfImage?.acf?.acf_text || `Default Text ${index + 1}`}
      </a>
    </li>
  ))}
</ul>



                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    
                  <img
  src={post.acf && post.acf.custom_img ? post.acf.custom_img.url : ''} 
alt={post.acf && post.acf.custom_img ? 'Image Alt Text' : ''}
  className="img-fluid"
  style={{ width: '100%' }}
/>

                      
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='/contact'>
                      Contact Now +
                    </a>
                  </div>
                </div>
                <div className='widget widget_download'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> Download
                  </h5>
                  <ul id="dynamicList">
                    
                  </ul>

                </div>
                </div>
              </div>
            
            </div>
          ) : (
            <p>Loading...</p>
          )}




        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea;
