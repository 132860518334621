import React, { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight, FaCalendarAlt, FaRegFolderOpen, FaRegUser, FaPlus, FaPinterest, FaChevronLeft, FaFacebookF, FaTwitter, FaInstagram, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom"; 

const PortfolioAreaOne = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [recentPosts, setRecentPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const selectedCategory = queryParams.get("category");
  const [portfolioPosts, setPortfolioPosts] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [tags, setTags] = useState([]);
  
  useEffect(() => {
    // Fetch recent posts
    const fetchRecentPosts = async () => {
      try {
        const recentPostsResponse = await axios.get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio?_embed&per_page=3`);
        setRecentPosts(recentPostsResponse.data);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };
  
    fetchRecentPosts();
  }, []);
  


const handleTagClick = (clickedTagId) => {
  setSelectedTag(clickedTagId);
  setCurrentPage(1);
  window.scrollTo({ top: 0, behavior: "smooth" });
};





  console.log('portfolioPosts:', portfolioPosts);

  
 


useEffect(() => {
  // Fetch data or update state based on selectedCategory or selectedTag
  // This will run whenever the query parameters change
  // For example, you can call a function here that fetches new data based on the selectedCategory or selectedTag
}, [selectedCategory, selectedTag]);

useEffect(() => {
  fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio_tag`)
    .then(response => response.json())
    .then(data => setTags(data))
    .catch(error => console.error('Error fetching tags:', error));
}, []);

useEffect(() => {
  // Fetch data or update state based on selectedCategory or selectedTag
  // This will run whenever the query parameters change
  // For example, you can call a function here that fetches new data based on the selectedCategory or selectedTag
}, [selectedCategory, selectedTag]);


useEffect(() => {
  // Fetch portfolio posts and categories
  const fetchPortfolioData = async () => {
    try {
      const postsResponse = await axios.get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio?_embed`);
      setPortfolioPosts(postsResponse.data);
      // ... fetch other data ...
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchPortfolioData();
    
  // Fetch tags
  fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio_tag`)
  .then(response => response.json())
  .then(data => setTags(data))
  .catch(error => console.error('Error fetching tags:', error));
}, []);

 // Calculate the total number of pages based on the total number of filtered portfolio posts
 const postsPerPage = 3;

 const [filteredPortfolioPosts, setFilteredPortfolioPosts] = useState([]); // Add this line
  // Move the filteredPortfolioPosts calculation into its own useEffect
  useEffect(() => {
    const filteredPosts = portfolioPosts.filter((post) => {
      if (selectedCategory && post.portfolio_category[0] !== parseInt(selectedCategory)) {
        return false;
      }
      if (selectedTag && !post.portfolio_tag.includes(parseInt(selectedTag))) {
        return false;
      }
      return true;
    });
    setFilteredPortfolioPosts(filteredPosts);
  }, [selectedCategory, selectedTag, portfolioPosts]);

  const totalPages = Math.ceil(filteredPortfolioPosts.length / postsPerPage);

  // Calculate the index range for the currently displayed posts
  const startIndex = (currentPage - 1) * postsPerPage;
  const endIndex = startIndex + postsPerPage;

  // Get the posts to be displayed on the current page
  const postsToDisplay = filteredPortfolioPosts.slice(startIndex, endIndex);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = portfolioPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to top smoothly
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const formatPostDate = (date) => {
    const options = { month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const limitParagraphLength = (text, maxLength) => {
    if (!text) return "";
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const increasedMaxLength = 200; 

  const [portfolioCategories, setPortfolioCategories] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio_category`)
      .then(response => response.json())
      .then(data => setPortfolioCategories(data))
      .catch(error => console.error('Error fetching portfolio categories:', error));
  }, []);

  return (
    <div className='blog-area pd-top-120 pd-bottom-120' data-aos="zoom-in">
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
          {postsToDisplay.map((post) => (
              <div className='single-blog-inner' key={post.id}>
                <div className='thumb'>
                  {post._embedded && post._embedded["wp:featuredmedia"] && (
                    <img src={post._embedded["wp:featuredmedia"][0].source_url} alt='img' />
                  )}
                <span className='date'>{formatPostDate(post.date)}</span>
                </div>
                <div className='details'>
                <ul className="blog-meta">
                      <li>
                        <FaRegUser /> By {post._embedded.author[0].name}
                      </li>
                      <li>
                        <FaRegFolderOpen /> {post._embedded["wp:term"][0][0].name}
                      </li>
                    </ul>
                    
                  <h2 className='title'>
                    <Link to={`/portfolio/${post.slug}`}>{post.title && post.title.rendered}</Link>
                  </h2>
                  <div dangerouslySetInnerHTML={{ __html: limitParagraphLength(post.content && post.content.rendered, increasedMaxLength) }} />
                  <Link className='btn btn-border-base mt-3' to={`/portfolio/${post.slug}`}>
                    Read More <FaPlus />
                  </Link>
                </div>
              </div>
            ))}
                 {/* Pagination */}
           <div className="pagination" style={{ padding: '15px'}}>
  {currentPage > 1 && (
    <a
      className="prev page-numbers"
      onClick={() => {
        setCurrentPage(currentPage - 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <FaChevronLeft />
    </a>
  )}

  {Array.from({ length: totalPages }).map((_, index) => (
    <a
      key={index}
      className={`page-numbers ${index + 1 === currentPage ? "current" : ""}`}
      onClick={() => {
        setCurrentPage(index + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      {index + 1}
    </a>
  ))}

  {currentPage < totalPages && (
    <a
      className="next page-numbers"
      onClick={() => {
        setCurrentPage(currentPage + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <FaChevronRight />
    </a>
  )}
</div>

          </div>
          <div className='col-lg-4 col-12'>
              <div className='td-sidebar'>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/icon.png' alt='planet-solutions-icon' />
                  </div>
                  <div className='details'>
                    <h5>Planet Solutions</h5>
                    
                    <ul className="social-media">
                    <li>
                      <a href="https://www.facebook.com/PlanetSolutionsau?mibextid=ZbWKwL">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/planetsolutionsau/">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com.au/Planetsolutionsau/">
                      <FaPinterest />
                      </a>
                    </li>
                  </ul>
                  </div>
                </div>
                <div className='widget widget_search'>
                <h4 className='widget-title'>AD Area</h4>
                <amp-ad width="100vw" height="320"
     type="adsense"
     data-ad-client="ca-pub-3971043314961130"
     data-ad-slot="1576322118"
     data-auto-format="rspv"
     data-full-width="">
  <div overflow=""></div>
</amp-ad>
                </div>
                <div className='widget widget-recent-post'>
                  <h4 className='widget-title'>Portfolio Archive</h4>
                  <ul>
    {recentPosts.map((post) => (
      <li key={post.id}>
        <div className="media">
          <div className="media-left">
            {/* Display featured image if available */}
            {post._embedded["wp:featuredmedia"] && (
              <img src={post._embedded["wp:featuredmedia"][0].source_url} alt="blog" />
            )}
          </div>
          <div className="media-body align-self-center">
            <h6 className="title">
              <Link to={`/portfolio/${post.slug}`}>{post.title.rendered}</Link>
            </h6>
            <div className="post-info">
              <FaCalendarAlt />
              <span>
                {/* Format post date */}
                {new Date(post.date).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                })}
              </span>
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
                </div>
                <div className='widget widget_catagory'>
  <h4 className='widget-title'>Catagory</h4>
  <ul>
    {recentPosts.map((post) => (
      <li key={post.id}>
        <Link to={`/portfolio/${post.slug}`}>{post.title && post.title.rendered}</Link>
      </li>
    ))}
  </ul>
</div>

                <div className='widget widget_tag_cloud mb-0'>
                  <h4 className='widget-title'>Tags</h4>
                  <div className='tagcloud'>
  {tags.map(tag => (
    <Link
      key={tag.id}
      to={`/portfolio?tag=${tag.id}`}
      onClick={() => handleTagClick(tag.id)}
    >
      {tag.name}
    </Link>
  ))}
</div>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    </div>
  );
};

export default PortfolioAreaOne;
