import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import LoadingBar from 'react-top-loading-bar'

import Contact from "./pages/Contact";
import HomeOne from "./pages/HomeOne";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import ScrollToTop from "react-scroll-to-top";
import 'react-toastify/dist/ReactToastify.css';
import TermsAndConditions from "./pages/Terms";
import PrivacyPolicy from "./pages/Privacy";
import PlanMain from "./pages/plan";
import Portfolio from "./pages/Portfolio";
import PortfolioGroup from "./pages/PortfolioGroup";
import NotificationContainer from "./components/NotificationContainer";
import Gallery from "./pages/Gallery";
import NotFound from "./pages/NotFound";



function App() {
  const loadingBarRef = useRef(null); // Create a reference for the loading bar

  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
      mirror: false,
    });
    AOS.refresh();
  }, []);

   // Function to simulate loading, replace this with your actual loading logic
   const simulateLoading = () => {
    loadingBarRef.current.continuousStart(); // Start the loading bar

    // Simulate completion after a delay (e.g., fetching data, etc.)
    setTimeout(() => {
      loadingBarRef.current.complete(); // Complete the loading bar
    }, 3000); // Change the delay time as needed
  };

  return (
    <><BrowserRouter>
    <LoadingBar color="#f11946" ref={loadingBarRef} />
      <Routes>
        <Route path="/" element={<HomeOne />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details/:slug" element={<BlogDetails />} />
    
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details/:slug" element={<ServiceDetails />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/plan&pricing" element={<PlanMain />} />
        <Route path="/portfolio/:slug" element={<Portfolio />} />
        <Route path="/portfolio" element={<PortfolioGroup />} />
        <Route path="/portfolio/gallery" element={<Gallery />} />
        <Route path="*" element={<NotFound />} />



      </Routes>
      <ScrollToTop smooth color="#246BFD" />

    </BrowserRouter>  <NotificationContainer/> </>
  );
}

export default App;
