import React, { useEffect, useRef } from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import PricingAreaOne from "../components/PricingAreaOne";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'; // Import the LoadingBar component

const Service = () => {
  const loadingBarRef = useRef(null); // Create a reference for the loading bar

  useEffect(() => {
    // Optional: Simulate a loading process when the Service component mounts
    loadingBarRef.current.continuousStart(); // Start the loading bar
    setTimeout(() => {
      loadingBarRef.current.complete(); // Complete the loading bar after a delay (simulating loading)
    }, 2000); // Change the delay time as needed
  }, []);

  return (
    <>
      <Helmet>
        <title>Services | Planet Solutions</title>
        <meta
          name="description"
          content="We specialize in Graphic Design, Web Design, and Ecommerce Solutions to boost your online presence and brand identity."
        />
        <link rel='canonical' href={`${window.location.origin}/service`} />
      </Helmet>
      {/* Loading Bar Component */}
      <LoadingBar color="#1F5ABC" ref={loadingBarRef} />

      {/* Navigation Bar */}
      <NavBar />

      {/* Breadcrumb */}
      <Breadcrumb title={"Service"} />

      {/* Service Area One */}
      <ServiceAreaGroup />

      {/* Pricing Area One */}
      <PricingAreaOne />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Service;
