import React, { useState,useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";

import { toast, Toaster } from "react-hot-toast";
const ContactMain = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    // Load the reCAPTCHA script once the component mounts
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LfKwxMoAAAAAEz-ACDmiYk4aR7QbkE0wfPAVMXJ";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Get the reCAPTCHA token
    const token = await window.grecaptcha.execute('6LfKwxMoAAAAAEz-ACDmiYk4aR7QbkE0wfPAVMXJ', { action: 'submit' });
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/send-email';
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, token }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Show success notification
        toast.success("Email sent successfully!");
  
        // Clear form fields
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      } else {
        // Show error notification
        toast.error("Failed to send email. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Show error notification
      toast.error("An error occurred. Please try again later.");
    }
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-area pt-4 pd-bottom-120'>
        <div className='container'>
          <div className='contact-page-inner bg-gray'>
            <div className='section-title mb-4 pb-2'>
              <h2 className='title'>Direct contact us? </h2>
              <p className='content mb-0'>
              Contact Planets Solutions for all your graphic design, web development, and e-commerce needs. Our expertise and experience shine through in every project. Trust us to take care of everything for your online presence and digital success.
              </p>
            </div>
            <form className="mt-4" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea
                            placeholder="Message"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-black mt-0 w-100 border-radius-5"
                        >
                          Submit now
                        </button>
                        
                      </div>
                    </div>
                  </form>
          </div>
        </div>
      </div>

      {/* contact list */}
      <div className='contact-page-list'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/13.svg' alt='img' />
                  
                </div>
                <div className='media-body'>
                  <a href="https://wa.me/+61451146977/?text=">
                  <h5>Contacts us</h5>
                  <h6>+61 451 146 977</h6>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/14.svg' alt='img' />
                </div>
                <div className='media-body'>
                <a href="mailto:support@planetsolutions.com.au">
                  <h5>Contacts us</h5>
                  <h6>support@planetsolutions.com.au</h6>
                  </a>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
  {/* Galaxy 3D */}
<div className="contact-galaxy">
  <iframe
    src="https://solarsystem.nasa.gov/gltf_embed/2393&#39"
    width="100%"
    height="450px"
    frameBorder="0"
    title="3D Galaxy"
  />
</div>


      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
