// Blog.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogGroup from "../components/BlogGroup";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { Helmet } from "react-helmet";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    // Fetch blog posts from WordPress REST API
    axios.get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed`)
      .then((response) => {
        setBlogPosts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);

  return (
    <>
     <Helmet>
      <title>Blog | Planet Solutions
          </title>
            <meta name="description" content="Explore our latest blog articles at Planet Solutions. Stay informed with our insights on Graphic Design, Web Design, Ecommerce Solutions, and more.
"/>
<link rel='canonical' href={`${window.location.origin}/blog`} />

</Helmet>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Blog"} />

      {/* Blog Group */}
      <BlogGroup blogPosts={blogPosts} />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Blog;
