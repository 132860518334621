// BlogGroup.js
import React, { useState, useEffect, useRef } from "react";
import { FaRegUser, FaRegFolderOpen, FaPinterest, FaQuoteRight, FaChevronRight, FaChevronLeft, FaCalendarAlt, FaCheckCircle, FaFacebookF, FaTwitter, FaInstagram, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"; 
import AdSenseAd from "../pages/AdSenseAd";
import LoadingBar from 'react-top-loading-bar'; // Import the LoadingBar component


const BlogGroup = ({ blogPosts }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCategory = queryParams.get("category");
  const selectedTag = queryParams.get("tag");

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3; // Number of posts to display per page

  const loadingBarRef = useRef(null); // Create a reference for the loading bar

  // Filter the blog posts based on selected category and tag
  const filteredBlogPosts = blogPosts.filter((post) => {
    if (selectedCategory && post._embedded["wp:term"][0][0].id.toString() !== selectedCategory) {
      return false;
    }
    if (selectedTag && !post.tags.includes(parseInt(selectedTag))) {
      return false;
    }
    return true;
  });

  // Calculate the total number of pages based on the total number of filtered blog posts
  const totalPages = Math.ceil(filteredBlogPosts.length / postsPerPage);

  // Calculate the index range for the currently displayed posts
  const startIndex = (currentPage - 1) * postsPerPage;
  const endIndex = startIndex + postsPerPage;

  // Get the posts to be displayed on the current page
  const postsToDisplay = filteredBlogPosts.slice(startIndex, endIndex);

 


  const handleTagClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCategoryClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // Optional: Simulate a loading process when the BlogGroup component mounts
    loadingBarRef.current.continuousStart(); // Start the loading bar
    setTimeout(() => {
      loadingBarRef.current.complete(); // Complete the loading bar after a delay (simulating loading)
    }, 2000); // Change the delay time as needed
   
    // Fetch data or update state based on selectedCategory or selectedTag
    // This will run whenever the query parameters change
    // For example, you can call a function here that fetches new data based on the selectedCategory or selectedTag
  }, [selectedCategory, selectedTag]);

  const limitParagraphLength = (text, maxLength) => {
    if (!text) return "";
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
// Increase the maximum character length to, for example, 200 characters
const increasedMaxLength = 200;

const [categories, setCategories] = useState([]);

useEffect(() => {
  fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/categories`)
    .then(response => response.json())
    .then(data => setCategories(data))
    .catch(error => console.error('Error fetching categories:', error));
}, []);

const [tags, setTags] = useState([]);

useEffect(() => {
  fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/tags`)
    .then(response => response.json())
    .then(data => setTags(data))
    .catch(error => console.error('Error fetching tags:', error));
}, []);

  return (
    <>
     <LoadingBar color="#1F5ABC" ref={loadingBarRef} /> 
      <div className="blog-area" data-aos="zoom-in"  data-aos-duration="2000">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
            {postsToDisplay.map((post) => (
                <div className="single-blog-inner" key={post.id}>
                  <div className="thumb">
                    {post._embedded["wp:featuredmedia"] &&
                      post._embedded["wp:featuredmedia"][0] && (
                        <img
                          src={post._embedded["wp:featuredmedia"][0].source_url}
                          alt={post.title.rendered}
                          className="p-4 img-fluid " 
                          style={{ width: '100%' }}
                        />
                      )}

                    <span className="date">
                      {new Date(post.date).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "short"
                      }).toUpperCase()}
                    </span>
                    <ul className="blog-meta">
                      <li>
                        <FaRegUser /> By {post._embedded.author[0].name}
                      </li>
                      <li>
                        <FaRegFolderOpen /> {post._embedded["wp:term"][0][0].name}
                      </li>
                    </ul>
                    
                    <h2 className="title">
                      <Link to={`/blog-details/${post.slug}`}>{post.title.rendered}</Link>
                    </h2>
                    <div dangerouslySetInnerHTML={{ __html: limitParagraphLength(post.excerpt.rendered, increasedMaxLength) }} />
                    <Link className="btn btn-border-base mt-3" to={`/blog-details/${post.slug}`}>
                      Touch for More <FaPlus />
                    </Link>
                  </div>
                </div>
              ))}
                     {/* Pagination */}
           <div className="pagination" style={{ padding: '15px'}}>
  {currentPage > 1 && (
    <a
      className="prev page-numbers"
      onClick={() => {
        setCurrentPage(currentPage - 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <FaChevronLeft />
    </a>
  )}

  {Array.from({ length: totalPages }).map((_, index) => (
    <a
      key={index}
      className={`page-numbers ${index + 1 === currentPage ? "current" : ""}`}
      onClick={() => {
        setCurrentPage(index + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      {index + 1}
    </a>
  ))}

  {currentPage < totalPages && (
    <a
      className="next page-numbers"
      onClick={() => {
        setCurrentPage(currentPage + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <FaChevronRight />
    </a>
  )}
</div>
            </div>
            <div className="col-lg-4 col-12" data-aos="fade-down" data-aos-duration="1000">
              <div className="td-sidebar">
                <div className="widget widget_author text-center">
                {blogPosts[0]?._embedded?.author[0] && (
              <div className='thumb'>
                <img
                  src={blogPosts[0]._embedded.author[0]?.avatar_urls?.[96] || 'default-image-url'} // Replace 'default-image-url' with a fallback image URL
                  alt='Author'
                />
              </div>
            )}
            {blogPosts[0]?._embedded?.author[0] && (
              <div className='details'>
                <h5>{blogPosts[0]._embedded.author[0]?.name || 'Author Name'}</h5>
                    <h6>By: Ghazanfer Quraishi </h6>
                    <ul className="social-media">
                    <li>
                      <a href="https://www.facebook.com/PlanetSolutionsau?mibextid=ZbWKwL">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.instagram.com/planetsolutionsau/">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com.au/Planetsolutionsau/">
                      <FaPinterest />
                      </a>
                    </li>
                  </ul>
                
                  </div>
                      )}
                </div>
            
                <div className="widget widget_search" >
                   {/* Vertical ads */}
      <h4 className='widget-title'>AD Area</h4>
      <AdSenseAd adClient="ca-pub-3971043314961130" adSlot="1576322118" />
                </div>
                <div className="widget widget-recent-post">
                <h4 className='widget-title'>Recent Blogs</h4>
                <ul>
  {blogPosts.slice(0, 3).map((post) => (
    <li key={post.id}>
      <div className='media'>
        <div className='media-left'>
          <img src={post._embedded["wp:featuredmedia"][0].source_url} alt='blog' />
        </div>
        <div className='media-body align-self-center'>
          <h6 className='title'>
            <Link to={`/blog-details/${post.slug}`}>
              {/* Use CSS to truncate title to 3 lines */}
              <div className="truncate-3-lines">
                {post.title.rendered}
              </div>
            </Link>
          </h6>
          <div className='post-info'>
            <FaCalendarAlt />
            <span>
              {new Date(post.date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long"
              })}
            </span>
          </div>
        </div>
      </div>
    </li>
  ))}
</ul>

                </div>
                <div className="widget widget_catagory">
                <h4 className='widget-title'>Category</h4>
                <ul className='category-items'>
                {categories.map(category => (
                  <li key={category.id}>
                    <Link to={`/blog?category=${category.id}`} onClick={handleCategoryClick}>
                      {category.name} <span>{category.count}</span>
                    </Link>
                  </li>
                ))}
              </ul>
                </div>
                <div className="widget widget_tag_cloud mb-0">
                <h4 className='widget-title'>Tags</h4>
      <div className='tagcloud'>
      {tags.map(tag => (
                  <Link key={tag.id} to={`/blog?tag=${tag.id}`} onClick={handleTagClick}>
                    {tag.name}
                  </Link>
                ))}
        </div>
                </div>
              </div>
            </div>
          </div>
    
        </div>
      </div>
    </>
  );
};

export default BlogGroup;
