import React, { useEffect, useRef } from "react";
import Breadcrumb from "../components/Breadcrumb";
import PricingAreaThree from "../components/PricingAreaThree";
import FooterOne from "../components/FooterOne"
import NavBar from "../components/NavBar";
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'; // Import the LoadingBar component


const PlanMain = () => {
  const loadingBarRef = useRef(null); // Create a reference for the loading bar
  useEffect(() => {
    // Optional: Simulate a loading process when the Service component mounts
    loadingBarRef.current.continuousStart(); // Start the loading bar
    setTimeout(() => {
      loadingBarRef.current.complete(); // Complete the loading bar after a delay (simulating loading)
    }, 2000); // Change the delay time as needed
  }, []);
  return (
    <>
     <LoadingBar color="#1F5ABC" ref={loadingBarRef} />
      <Helmet>
      <title>Plan & Pricing | Planet Solutions
          </title>
            <meta name="description" content="'Planet Solutions' represents considerable authority in Website Development, Graphic Design, and Social Media Solutions"/>
 <link rel='canonical' href={`${window.location.origin}/plan&pricing`} />
</Helmet>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Plan & Pricing"} />

      {/* Contact Main */}
      <PricingAreaThree />

      

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default PlanMain;
