import React, { useState, useEffect } from 'react';
import Gallery from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link

function PestsGallery() {
  const [imagesData, setImagesData] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  useEffect(() => {
    // Fetch images from WordPress API
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio?_embed`)
      .then(response => response.json())
      .then(data => {
        // Extract image URLs, alt text, and slugs from API response and set them in the state
        const images = data.map(item => ({
          src: item._embedded['wp:featuredmedia'][0].source_url,
          alt: item._embedded['wp:featuredmedia'][0].alt_text || '',
          slug: item.slug, // Extract slug directly from the item object
        }));
        setImagesData(images);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);
  

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const showNext = () => {
    setLightboxIndex((lightboxIndex + 1) % imagesData.length);
  };

  const showPrev = () => {
    setLightboxIndex((lightboxIndex + imagesData.length - 1) % imagesData.length);
  };
  return (
    <div className="container">
    <div className='case-study-area bg-relative pd-top-110'>
    <img
      className='animate-img-1 top_image_bounce'
      src='assets/img/banner/2.png'
      alt='img'
    />
    <div className='container'>
      <div className='section-title text-center'>
        <h3 className='sub-title'>Portfolio</h3>
        <h2 className='title'>
          Our <span>Creative </span> Works
        </h2>
      </div></div>
      <div className="row">
        
      {imagesData.map((image, index) => (
  <div key={index} className="col-md-3 mb-4">
    <img
      alt={image.alt}
      className="img-fluid"
      onClick={() => openLightbox(index)}
      src={image.src}
      style={{ cursor: 'pointer' }}
    />
  </div>
))}

     
      </div>
      {lightboxOpen && (
        <Gallery
          mainSrc={imagesData[lightboxIndex].src}
          nextSrc={imagesData[(lightboxIndex + 1) % imagesData.length].src}
          prevSrc={imagesData[(lightboxIndex + imagesData.length - 1) % imagesData.length].src}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={showPrev}
          onMoveNextRequest={showNext}
        />
      )}
    </div>
      
        <div className="row bg-light rounded ">
          <div className="col-md-12 col-lg-12 col-sm-12 col-12  " >
            <div className='row'>
            <div className='col-8'>
            <div className='row align-items-center'>
            <div className='col-2 m-auto'>
           <span className='cte-icon'> <FontAwesomeIcon id='icon' icon={faLightbulb} style={{ color: '#ffff' }} />
                </span>
                </div>
                <div className='col-10 p-4 m-auto'>
               
                  <h3 className='int-1' >Explore Our Successes</h3>
            
            </div>
            </div>
            </div>
         
            <div className='col-4 m-auto '>
  
    <Link to={'/portfolio'}>
      <a href='' className=' btn btn-black mt-0 w-100 border-radius-5'>VIEW PORTFOLIO </a>
      </Link>
</div>


          </div>
        </div>
      </div>
      </div>
    
  );
}

export default PestsGallery;