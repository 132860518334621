import React, { useState, useEffect } from 'react';
import Gallery from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link
import {FaPlus} from "react-icons/fa";

function PortfolioGallery() {
  const [imagesData, setImagesData] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [posts, setPosts] = useState([]); // Define a state variable for posts

  useEffect(() => {
    // Fetch images from WordPress API
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio?_embed`)
      .then((response) => response.json())
      .then((data) => {
        // Extract image URLs, alt text, and slugs from API response and set them in the state
        const images = data.map((item) => ({
          src: item._embedded['wp:featuredmedia'][0].source_url,
          alt: item._embedded['wp:featuredmedia'][0].alt_text || '',
          slug: item.slug,
        }));
        setImagesData(images);
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });

    // Fetch post data (example code, adjust as needed)
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio?_embed`)
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
      });
  }, []);

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const showNext = () => {
    setLightboxIndex((lightboxIndex + 1) % imagesData.length);
  };

  const showPrev = () => {
    setLightboxIndex((lightboxIndex + imagesData.length - 1) % imagesData.length);
  };

  return (
    <div className='blog-area pd-top-120 pd-bottom-120' data-aos="fade-down"
    data-aos-easing="linear"
  data-aos-duration="2000">
<div className="container">
  <div className="row">
    {imagesData.map((image, index) => {
      // Find the corresponding post for the current image
      const post = posts.find((post) => post.slug === image.slug);

      return (
        <div key={index} className="col-md-3 mb-4">
          <img
            alt={image.alt}
            className="img-fluid"
            onClick={() => openLightbox(index)}
            src={image.src}
            style={{ cursor: 'pointer' }}
            
          />
          {/* Render links to portfolio items based on post data */}
          {post && (
            <div>
                 <Link to={`/portfolio/${post.slug}`}>
              <h4 className="gallery-title"  >
               
                  {post.title && post.title.rendered}
              
              </h4>
              </Link>
            </div>
          )}
        </div>
      );
    })}
  </div>
  {lightboxOpen && (
    <Gallery
      mainSrc={imagesData[lightboxIndex].src}
      nextSrc={imagesData[(lightboxIndex + 1) % imagesData.length].src}
      prevSrc={imagesData[(lightboxIndex + imagesData.length - 1) % imagesData.length].src}
      onCloseRequest={closeLightbox}
      onMovePrevRequest={showPrev}
      onMoveNextRequest={showNext}
    />
  )}
</div>
</div>
  );
}

export default PortfolioGallery;
