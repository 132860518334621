import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <>

    
<Helmet>
    <title>404 Not Found
        </title>
          <meta name="description" content="'Planet Solutions' represents considerable authority in Website Development, Graphic Design, and Social Media Solutions"/>

</Helmet>

      {/* Navigation Bar */}
      <NavBar />

      {/* Breadcrumb */}
      <Breadcrumb  title={"404 Not Found"}  />

       {/* Image */}
       <Link to="/">
       <img
        src="assets/img/404-Planet-Solutions.png" // Replace with the actual image path
        alt="404 Error"
        style={{ maxWidth: "100%", height: "auto", paddingBottom: "52px", }} 
        className="img-fluid"// You can style the image as needed
      />
      </Link>
       {/* Button */}

      

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default NotFound;
