import React, { useEffect, useRef } from "react";
import Breadcrumb from "../components/Breadcrumb";
import ContactMain from "../components/ContactMain";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'; // Import the LoadingBar component

const Contact = () => {
  const loadingBarRef = useRef(null); // Create a reference for the loading bar
  useEffect(() => {
    
    // Optional: Simulate a loading process when the Service component mounts
    loadingBarRef.current.continuousStart(); // Start the loading bar
    setTimeout(() => {
      loadingBarRef.current.complete(); // Complete the loading bar after a delay (simulating loading)
    }, 2000); // Change the delay time as needed
  }, []);
  return (
    <>
     <LoadingBar color="#1F5ABC" ref={loadingBarRef} />
     <Helmet>
      <title>Contact | Planet Solutions
          </title>
            <meta name="description" content="Get in touch with Planet Solutions for all your inquiries and to discuss your Graphic Design, Web Design, and Ecommerce Solutions needs. We're here to help!
"/>
<link rel='canonical' href={`${window.location.origin}/contact`} />
</Helmet>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Contact"} />

      {/* Contact Main */}
      <ContactMain />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Contact;
