import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaRegUser } from "react-icons/fa";

const CommentForm = ({ postId }) => {
  const [commentText, setCommentText] = useState("");
  const [commentAuthor, setCommentAuthor] = useState(
    localStorage.getItem("commentAuthor") || ""
  );
  const [commentAuthorEmail, setCommentAuthorEmail] = useState(
    localStorage.getItem("commentAuthorEmail") || ""
  );
  const [comments, setComments] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("commentAuthor") && localStorage.getItem("commentAuthorEmail")
  );
  const [showSignInForm, setShowSignInForm] = useState(!isLoggedIn);

  console.log("Received postId in CommentForm:", postId);

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleAuthorChange = (event) => {
    const authorValue = event.target.value;
    setCommentAuthor(authorValue);
  };

  const handleAuthorEmailChange = (event) => {
    const emailValue = event.target.value;
    setCommentAuthorEmail(emailValue);
  };

  const submitComment = () => {
    if (commentText.trim() === "") {
      // You can add validation here to ensure the comment is not empty
      return;
    }

    const newComment = {
      comment_post_ID: postId,
      comment_content: commentText,
      comment_approved: 0,
      // Add other comment-related fields here
    };

    if (isLoggedIn) {
      // If the user is logged in, use their name and email
      newComment.comment_author = commentAuthor;
      newComment.comment_author_email = commentAuthorEmail;
    } else {
      // If the user is not logged in, save their name and email to localStorage
      localStorage.setItem("commentAuthor", commentAuthor);
      localStorage.setItem("commentAuthorEmail", commentAuthorEmail);
    }

    console.log("Sending this comment data:", newComment);

    axios
      .post(
        `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/custom-comment-api/v1/post-comment`,
        newComment
      )
      .then((response) => {
        console.log("Comment submitted successfully:", response);
        // Clear the comment input
        setCommentText("");

        // Fetch and update the comments list
        fetchComments(postId);
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
  };

  const fetchComments = (postId) => {
    axios
      .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/comments?post=${postId}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  };

  useEffect(() => {
    if (postId) {
      fetchComments(postId);
    }
  }, [postId]);

  const signInForm = (
    <div className="comment-form">
      <h4>Sign In to Add a Comment</h4>
      <div className="single-input-inner style-border">
        <input
          type="text"
          placeholder="Your Name"
          value={commentAuthor}
          onChange={handleAuthorChange}
        />
      </div>
      <div className="single-input-inner style-border">
        <input
          type="email"
          placeholder="Your Email"
          value={commentAuthorEmail}
          onChange={handleAuthorEmailChange}
        />
      </div>
      <button
        className="btn btn-black mt-0 w-100 border-radius-5"
        onClick={() => {
          setIsLoggedIn(true);
          setShowSignInForm(false);
        }}
      >
        Sign In
      </button>
    </div>
  );

  const commentTextArea = (
    <div className="comment-form">
      <h4>Commenting as {commentAuthor}</h4>
      <div className="single-input-inner style-border">
        <textarea
          rows="4"
          placeholder="Write your comment here..."
          value={commentText}
          onChange={handleCommentChange}
        ></textarea>
      </div>
      <button className="btn btn-black mt-0 w-100 border-radius-5" onClick={submitComment}>
        Submit Comment
      </button>
    </div>
  );

  return (
    <div>
      {showSignInForm ? signInForm : commentTextArea}
      {comments.length > 0 && (
        <div className="comments-section list-group">
          <h4>Comments</h4>
          <ul className="comment-list">
            {comments.map((comment) => (
              <li key={comment.id} className="comment">
                <div className="comment-author">
                  <FaRegUser /> {comment.author_name}
                </div>
                <div
                  className="comment-content"
                  dangerouslySetInnerHTML={{ __html: comment.content.rendered }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CommentForm;
