import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // Import useParams to get the slug from the URL
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import BlogDetailsArea from "../components/BlogDetailsArea";
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'; // Import the LoadingBar component

const BlogDetails = () => {
  const loadingBarRef = useRef(null); // Create a reference for the loading bar
  const { slug } = useParams(); // Get the slug from the URL
  const [title, setTitle] = useState(""); // State to store the title

  // Function to fetch the title from WordPress based on the slug
  const fetchTitle = async (slug) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/posts?slug=${slug}&_embed`
      );
      const data = await response.json();
      // Assuming that the API response contains the title field
      const postTitle = data[0]?.title?.rendered || "Default Title";
      setTitle(postTitle);
    } catch (error) {
      console.error("Error fetching title:", error);
    }
  };

  // Use useEffect to fetch the title when the component mounts or when the slug changes
  useEffect(() => {
     // Optional: Simulate a loading process when the Service component mounts
     loadingBarRef.current.continuousStart(); // Start the loading bar
     setTimeout(() => {
       loadingBarRef.current.complete(); // Complete the loading bar after a delay (simulating loading)
     }, 2000); // Change the delay time as needed

    fetchTitle(slug);
  }, [slug]); // Re-fetch when the slug changes

  return (
    <>
     <LoadingBar color="#1F5ABC" ref={loadingBarRef} />
     <Helmet>
      <title>{title}
          </title>
            <meta name="description" content="Explore our latest blog articles at Planet Solutions. Stay informed with our insights on Graphic Design, Web Design, Ecommerce Solutions, and more.
"/>
<link rel='canonical' href={`${window.location.origin}/blog-details/${slug}`} />
</Helmet>
      {/* Navigation Bar */}
      <NavBar />

      {/* Breadcrumb with the fetched slug */}
      <Breadcrumb title={title || slug} />

      {/* Blog Details Area */}
      <BlogDetailsArea />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default BlogDetails;
