import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation } from "react-router-dom";

function NotificationContainer() {
  useEffect(() => {
    const eventSource = new EventSource(process.env.REACT_APP_EVENT_SOURCE_URL);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);

      // Ensure that the data from the Automator webhook matches your expectations
      const { title, excerpt, post_thumbnail } = data;

      // Display a notification using react-toastify
      if (title && excerpt) {
        console.log('post_thumbnail:', post_thumbnail);
        toast.success(
          <div className="notification-content">
              <Link to="/blog">
            <div className="row">
           
              <div className="col-12">
                <h4 style={{fontSize: '16px'}}>{title}</h4>
                <p style={{fontSize: '10px'}}>{truncateDescription(excerpt, 1)}</p>
                 <p style={{color: '#EE0020', fontSize: '16px'}}>we have uploaded new post click Here</p>
              </div>
            </div>
            </Link>
          </div>,
          {
            position: 'bottom-left',
            autoClose: false,
            closeButton: true,
          }
        );
      } else {
        // Display a fallback message if any required field is missing
        toast.error('Incomplete data received from webhook');
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const truncateDescription = (text, lines) => {
    if (!text) {
      return ''; // Handle the case where text is undefined or null
    }

    const textArray = text.split('\n');
    if (textArray.length > lines) {
      return textArray.slice(0, lines).join('\n');
    }
    return text;
  };

  return null;
}

export default NotificationContainer;
