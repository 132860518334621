import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { FaRegUser, FaRegFolderOpen, FaPinterest, FaQuoteRight, FaChevronRight, FaChevronLeft, FaCalendarAlt, FaCheckCircle, FaFacebook, FaTwitter, FaInstagram, FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
import CommentForm from "./CommentForm";
import AdSenseAd from "../pages/AdSenseAd";


const BlogDetailsArea = () => {
  const { slug } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCategory = queryParams.get("category");
  const selectedTag = queryParams.get("tag");
  
  const [blogPost, setBlogPost] = useState(null);
  const [author, setAuthor] = useState(null);
  const [postDate, setPostDate] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");


  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };
  const submitComment = () => {
    if (commentText.trim() === "") {
      // You can add validation here to ensure the comment is not empty
      return;
    }
  
    const newComment = {
      post: blogPost.id, // The ID of the blog post
      content: commentText,
    };
  
    axios
      .post(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/comments`, newComment)
      .then((response) => {
        // Comment successfully posted
        // You can update the comments list with the new comment here
        console.log("Comment submitted successfully:", response);
        setComments([...comments, response.data]);
        setCommentText(""); // Clear the comment input
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
  };
    
  // Comments WP
  const fetchComments = (postId) => {
    axios
      .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/comments?post=${postId}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  };
   


  const handleTagClick = (tagId) => {
    const tagURL = selectedCategory ? `/blog?category=${selectedCategory}&tag=${tagId}` : `/blog?tag=${tagId}`;
    window.location.href = tagURL;
  };

  const handleCategoryClick = (categoryId) => {
    const categoryURL = selectedTag ? `/blog?tag=${selectedTag}&category=${categoryId}` : `/blog?category=${categoryId}`;
    window.location.href = categoryURL;
  };

  useEffect(() => {
    if (slug) {
      axios
        .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/posts?slug=${slug}&_embed`)
        .then((response) => {
          console.log("API response:", response.data);
          if (response.data.length > 0) {
            setBlogPost(response.data[0]);
            fetchAuthor(response.data[0].author);
            // Fetch comments for the post
          fetchComments(response.data[0].id);
          }
        })
        .catch((error) => {
          console.error("Error fetching blog data:", error);
        });
    }
  }, [slug]);

  useEffect(() => {
    fetchCategories();
    fetchTags();
  }, []);

  useEffect(() => {
    if (blogPost) {
      // Get the date from the fetched blog post
      const postDate = new Date(blogPost.date);

      // Format the date as "DD MMM"
      const options = { day: 'numeric', month: 'short' };
      const formattedDate = postDate.toLocaleDateString('en-US', options).toUpperCase();

      // Set the formatted date in the state
      setPostDate(formattedDate);
    }
  }, [blogPost]);

  useEffect(() => {
    // Fetch recent blog posts
    axios
      .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/posts?per_page=3&_embed`)
      .then((response) => {
        console.log("Recent posts response:", response.data);
        setRecentPosts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching recent posts:", error);
      });
  }, []);

  const fetchAuthor = (authorId) => {
    axios
      .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/users/${authorId}`)
      .then((response) => {
        console.log("Author response:", response.data);
        setAuthor(response.data);
      })
      .catch((error) => {
        console.error("Error fetching author data:", error);
      });
  };

  const fetchCategories = () => {
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/categories`)
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));
  };

  const fetchTags = () => {
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/tags`)
      .then(response => response.json())
      .then(data => setTags(data))
      .catch(error => console.error('Error fetching tags:', error));
  };

  return (
    <div className="blog-area pd-top-60 pd-bottom-120" data-aos="zoom-in" data-aos-duration="1000">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {blogPost && (
              <div className="blog-details-page-content">
                <div className="single-blog-inner">
                  <div className="thumb">
                    {blogPost._embedded["wp:featuredmedia"] && (
                      <img
                        src={blogPost._embedded["wp:featuredmedia"][0].source_url}
                        alt={blogPost.title.rendered}
                        className="img-fluid"
                        style={{ width: '100%' }}
                      />
                    )}
                    {postDate && <span className="date">{postDate}</span>}
                  
                  </div>
                  <div className="details">
                    <ul className="blog-meta">
                      <li>
                        <FaRegUser /> By {blogPost._embedded.author[0]?.name || "Unknown Author"}
                      </li>
                      <li>
                        <FaRegFolderOpen /> {blogPost._embedded["wp:term"][0][0]?.name || "Uncategorized"}
                      </li>
                    </ul>
                    <h3 className="blog-title">{blogPost.title.rendered}</h3>
                    <div dangerouslySetInnerHTML={{ __html: blogPost.content.rendered }} />
                  </div>
                </div>
              </div>
            )}
            <div className="wp-coment">
            {/* {comments.length > 0 && (
  <div className="comments-section">
    <h4>Comments</h4>
    <ul className="comment-list">
       {comments.length > 0 && (
                <div className="comments-section">
                  <h4>Comments</h4>
                  <ul className="list-group">
                    {comments.map((comment) => (
                      <li key={comment.id} className="list-group-item">
                        <div className="comment-author">
                          <FaRegUser /> {comment.author_name}
                        </div>
                        <div className="comment-content" dangerouslySetInnerHTML={{ __html: comment.content.rendered }} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
    </ul>
  </div>
)} */}
<CommentForm postId={blogPost ? blogPost.id : null} />


</div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="td-sidebar">
            {author && (
                <div className="widget widget_author text-center" data-aos="fade-down" data-aos-duration="1000">
                  <div className="thumb" >
                    <img src={author.avatar_urls?.["96"]} alt={author.name} />
                  </div>
                  <div className="details">
                    <h5>{author.name}</h5>
                  <h6>By: Ghazanfer Quraishi  </h6>
                  <ul className="social-media">
                    <li>
                      <a href="https://www.facebook.com/PlanetSolutionsau?mibextid=ZbWKwL">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.instagram.com/planetsolutionsau/">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com.au/Planetsolutionsau/">
                      <FaPinterest />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
               )}

<div className="widget widget_search">
                   {/* Vertical ads */}
      <h4 className='widget-title'>AD Area</h4>
      <AdSenseAd adClient="ca-pub-3971043314961130" adSlot="1576322118" />
                </div>
         
              <div className="widget widget-recent-post"  data-aos="fade-down" data-aos-duration="1000">
              <h4 className="widget-title">Recent News</h4>
              <ul>
  {recentPosts.map((post) => (
    <li key={post.id}>
      <div className="media">
        <div className="media-left">
          {/* Display featured image if available */}
          {post._embedded["wp:featuredmedia"] && (
            <img src={post._embedded["wp:featuredmedia"][0].source_url} alt="blog" />
          )}
        </div>
        <div className="media-body align-self-center">
          <h6 className="title">
            <Link to={`/blog-details/${post.slug}`}>
              {/* Use CSS to truncate title to 3 lines */}
              <div className="truncate-3-lines">
                {post.title.rendered}
              </div>
            </Link>
          </h6>
          <div className="post-info">
            <FaCalendarAlt />
            <span>
              {/* Format post date */}
              {new Date(post.date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
              })}
            </span>
          </div>
        </div>
      </div>
    </li>
  ))}
</ul>

              </div>
              <div className="widget widget_catagory">
              <h4 className='widget-title'>Category</h4>
              <ul className='category-items'>
                  {categories.map(category => (
                    <li key={category.id}>
                      <Link to={`/blog-details/${slug}?category=${category.id}`} onClick={() => handleCategoryClick(category.id)}>
                        {category.name} <span>{category.count}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
                </div>
              <div className="widget widget_tag_cloud mb-0">
              <h4 className='widget-title'>Tags</h4>
      <div className='tagcloud'>
                  {tags.map(tag => (
                    <Link key={tag.id} to={`/blog-details/${slug}?tag=${tag.id}`} onClick={() => handleTagClick(tag.id)}>
                      {tag.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailsArea;
