import React from "react";
import { cloudinary } from "../pages/cloudinaryConfig";
import { Image } from 'cloudinary-react';

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
            
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                {/* <img
                  className='main-img'
                  src='assets/img/about/Service-img.webp'
                  alt='img'
                /> */}

                
<Image
        cloudName="dfxv3emcv"
        publicId="PlanetSolutions/Service-img_xavyxw"
        className="main-img"
        alt="Graphic Design Services planet-solutions"
        format="webp"
      />
              </div>
            </div>
            <div
              className='col-lg-6 '
             
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h3 className='sub-title'>ABOUT US</h3>
                <h1 className='title'>
                  PLANET <span>SOLUTIONS</span> |
                  Developing Future Oriented Solutions
                </h1>
                <p className='content mb-4 mb-xl-5'>
                ‘Planet Solutions’ represents considerable authority in Website Development, Graphic Design, and Social Media Solutions, all with a large number of alternatives to suit your necessities.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h2 style={{fontSize: "20px"}}>Our Mission</h2>
                        <p>
                        To provide high quality, professional and complete creative services in all design & communication fields including:
Graphic Design / Printing / Advertising / Branding / Packaging / Promotional Materials Design & Supply / Multimedia / Web Design, Development & Hosting
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h3 style={{fontSize: "20px"}}>Our Vision</h3>
                        <p>
                        Develop high quality and affordable Graphics and web solutions that enable clients to establish a presence online, increase profitability, and continually improve productivity. Create Always, Think All Ways”

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
