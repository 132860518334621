import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import NotificationBanner from "./NotificationBanner";



const NavBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const location = useLocation(); // Get the current route

 // Scroll to top when route changes
 useEffect(() => {
  window.scrollTo(0, 0);
}, [location.pathname]); // This will trigger when location.pathname changes



  const menuActive = () => {
    setActive(!active);
  };

  const searchActive = () => {
    setSearchShow(!searchShow);
    console.log("hello");
  };

  return (
    <>
      {/* search popup start */}
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup"}
        id="td-search-popup"
      >
        <form
          action="/search" // Update the action attribute to "/search"
          className="search-form"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search..... "
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <button type="submit" className="submit-btn">
            <Link to={`/search?query=${searchQuery}`}>
             <FaSearch />
            </Link>
          </button>
        </form>
      </div>
      {/* search popup end */}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id="body-overlay"
      ></div>
      {/* navbar start */} 
      {/* <div className="container-fluid">
  <NotificationBanner/>
  </div> */}
    
      <section className="sec-1">
     
        <nav className="navbar navbar-area  navbar-expand-lg navbar-fixed bg-light py">
          
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button
                onClick={menuActive}
                className={
                  active
                    ? "menu toggle-btn d-block d-lg-none open"
                    : "menu toggle-btn d-block d-lg-none"
                }
                data-target="#itech_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <Link to="/">
                <img
                  src="/assets/img/planet-solutions-logo.png"
                  alt="img"
                />
              </Link>
            </div>
            <div className="nav-right-part nav-right-part-mobile">
             {/*  <span className="search-bar-btn" onClick={searchActive}>
                <FaSearch />
              </span>*/}
            </div>
            <div
              className={
                active
                  ? "collapse navbar-collapse sopen"
                  : "collapse navbar-collapse"
              }
              id="itech_main_menu"
            >
              <ul className="navbar-nav menu-open text-lg-end">
                <li>
                  <Link to="/" className={`nav-link ${location.pathname === "/" ? "active" : ""}`}>Home</Link>
                </li>
                <li>
                  <Link to="/service" className={`nav-link ${
              location.pathname === "/service" ? "active" : ""
            }`}>Services</Link>
                  {/*  <ul className='sub-menu' className='menu-item-has-children'>
                      <li>
                        <Link to='/service'>Service</Link>
                      </li>
                      <li>
                        <Link to='/service-details'>Service Single</Link>
                      </li>
                  </ul>  */}
                </li>
                <li>
                  <Link to="/about" className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} >About Us</Link>
                </li>
                <li>
                  <Link to="/plan&pricing" className={`nav-link ${location.pathname === "/plan&pricing" ? "active" : ""}`} >Plan & Pricing</Link>
                </li>
                <li>
                  <Link to="/blog" className={`nav-link ${location.pathname === "/blog" ? "active" : ""}`} >Blog</Link>
                </li>
                <li>
                  <Link to="/contact" className={`nav-link ${location.pathname === "/contact" ? "active" : ""}`}>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop align-self-center">
              <a className="navbar-phone" href="https://wa.me/+61451146977/?text=">
                <span className="icon">
                  <RiWhatsappFill />
                </span>
                <span>Need help?</span>
                <h5>+61 451 146 977</h5>
              </a>
            </div>
          </div>
        </nav>
      </section>
      {/* navbar end */}
    </>
  );
};

export default NavBar;

