import React, { useEffect } from 'react';

const AdSenseAd = ({ adClient, adSlot }) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    (adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-format="auto"
    />
  );
};

export default AdSenseAd;
