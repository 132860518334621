import React, { useState, useEffect } from "react";
import { FaArrowRight, FaEnvelope, FaFacebookF, FaInstagram, FaPhoneAlt, FaTwitter, FaYoutube, FaCalendarAlt, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';



const FooterOne = () => {


  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    // Fetch the latest posts from the WordPress REST API using the provided URL
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&per_page=3`)
      .then(response => response.json())
      .then(data => {
        setLatestPosts(data);
      })
      .catch(error => {
        console.error('Error fetching latest posts:', error);
      });
  }, []);

  //Fetch services from wp
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Define the WordPress API endpoint URL
    const apiUrl = `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/service?_embed&order=asc`;

    // Fetch data from the WordPress API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Assuming data is an array of service objects
        // Use slice to limit the number of services to 6
        setServices(data.slice(0, 6));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  return (
    <>

    
      <footer className="footer-area bg-black bg-cover">
        <div className="footer-subscribe">
   
          <div className="container">
            <div className="footer-subscribe-inner bg-cover" style={{ backgroundImage: 'url("../assets/img/bg/6.png")' }}>
              <div className="row">
                <div className="col-lg-4">
                  <h2 className="mb-lg-0 mb-3">Subscribe To Our Newsletter</h2>
                </div>
                <div className="col-lg-6 align-self-center text-lg-end">
                 {/* Your Sendy Subscription Form */}
                 <form
        action="https://sendy.planetsolutions.com.au/subscribe"
        method="POST"
        accept-charset="utf-8"
      >
        {/* Name Input Field */}
        <div className="col-lg-12">
          <div className="single-input-inner style-border">
            <input placeholder="Your Name" type="text" name="name" id="name" /><br />
          </div>
        </div>
        {/* Email Input Field */}
        <div className="col-lg-12">
          <div className="single-input-inner style-border">
            <input placeholder="Your Email" type="email" name="email" id="email" /><br />
          </div>
        </div>


        {/* Hidden Fields */}
        <input type="hidden" name="list" value="yV0XHnMquMNJO86CHWtLuw" />
        <input type="hidden" name="subform" value="yes" />
        <input className="ty-submit" type="submit" name="submit" id="submit"  />
      </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {/* Column 1 */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="/assets/img/planet-solutions-logo-White.png" alt="img" />
                </div>
                <div className="details">
                  <p>
                  Developing Future Oriented Solutions
                  </p>
                  <a href="https://wa.me/+61451146977/?text=">
                  <p className="mt-3">
                    <FaWhatsapp />+61 451 146 977
                  </p>
                  </a>
                  <a href="mailto:support@planetsolutions.com.au">
                  <p className="mt-2">
                    <FaEnvelope /> support@planetsolutions.com.au
                  </p>
                  </a>
                  <ul className="social-media">
                    <li>
                      <a href="https://www.facebook.com/PlanetSolutionsau?mibextid=ZbWKwL">
                        <FaFacebookF />
                      </a>
                    </li>
                   
                    <li>
                      <a href="https://www.instagram.com/planetsolutionsau/">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@planetsolutionsau">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Column 2 */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h3 className="widget-title">Our Service</h3>
                <ul>
      {services.map((service) => (
        <li key={service.id}>
          <Link to={`/service-details/${service.slug}`}>
            <FaArrowRight /> {service.title.rendered}
          </Link>
        </li>
      ))}
    </ul>
              </div>
            </div>
            {/* Column 3 */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Popular pages</h4>
                <ul>
                  <li>
                    <Link to="/plan&pricing">
                      <FaArrowRight /> Plan & Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaArrowRight /> Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <FaArrowRight />About Us{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      <FaArrowRight /> Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <FaArrowRight /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <FaArrowRight /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Column 4 */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Recent Posts</h4>
          <ul>
  {latestPosts.slice(0, 2).map(post => (
    <li key={post.id}>
      <div className="media">
        <div className="media-left">
          {/* Use post featured image if available */}
          {post._embedded && post._embedded['wp:featuredmedia'] && (
            <img
              src={post._embedded['wp:featuredmedia'][0].source_url}
              alt="blog"
            />
          )}
        </div>
        <div className="media-body align-self-center">
          <div className="post-info mb-2">
            <FaCalendarAlt />
            <span>{new Date(post.date).toLocaleDateString()}</span>
          </div>
          <h5 className="title mb-0" style={{fontWeight: "400", fontSize: "16px"}}>
            <Link to={`/blog-details/${post.slug}`}>
              {/* Use CSS to truncate title to 3 lines */}
              <div className="truncate-3-lines">
                {post.title && post.title.rendered}
              </div>
            </Link>
          </h5>
        </div>
      </div>
    </li>
  ))}
</ul>

              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© PlanetSolutions 2023 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <Link to="/termsandconditions">
                Terms &amp; Condition
                </Link>
                <Link to="/privacy">
                Privacy Policy
                </Link>
                <Link to="/contact">
                Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </footer>
      
    </>
  );
};

export default FooterOne;
