import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube, faCrown, faBolt } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";

const PricingAreaOne = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    selectedPackage: "",
    selectedPackagePrice: "",
  });

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    selectedPackage: "",
    selectedPackagePrice: "",
  });

  // const handlePackageSelect = (packageType) => {
  //   setSelectedPackage(packageType);
  //   setFormData({ ...formData, selectedPackage: packageType }); // Update selectedPackage in formData
  //   handleShow();
  // };
  
  const handlePackageSelect = (packageType) => {
    setSelectedPackage(packageType);
  
    // Update selectedPackage and selectedPackagePrice in formData
    setFormData({
      ...formData,
      selectedPackage: packageType,
      selectedPackagePrice: packageDetails[packageType],
    });
  
    handleShow();
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "selectedPackage") {
      setSelectedPackage(value); // Update selectedPackage state
    }

 // Validate required fields
if (value.trim() === "") {
  setFormErrors({ ...formErrors, [name]: "This field is required" });
} else if (name === "name" && value.trim().length < 3) {
  setFormErrors({ ...formErrors, [name]: "Name must be at least 3 characters long" });
} else {
  setFormErrors({ ...formErrors, [name]: "" });
}

    // Validate email format
    if (name === "email" && value !== "" && !/\S+@\S+\.\S+/.test(value)) {
      setFormErrors({ ...formErrors, email: "Invalid use @example.com" });
    } else {
      setFormErrors({ ...formErrors, email: "" });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check for any form errors before submitting
    if (Object.values(formErrors).some((error) => error !== "")) {
      toast.error("Please fill in all required fields correctly.");
      return;
    }
  
    try {
      // Include selectedPackage in formData
      const formDataWithPackage = { ...formData, selectedPackage };
  
      // Your fetch logic for sending email
      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Show success notification
        toast.success("Email sent successfully!");
  
        // Clear form fields after successful submission
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
          selectedPackage: "",
          selectedPackagePrice: "",
        });
  
        // Close the modal
        handleClose();
      } else {
        // Show error notification
        toast.error("Failed to send email. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Show error notification
      toast.error("An error occurred. Please try again later.");
    }
  };

  const getPackageIcon = () => {
    switch (selectedPackage) {
      case "For Startups":
        return <FontAwesomeIcon icon={faCube} className="cws_vc_shortcode_icon_3x flaticon-cube" />;
      case "ULTRA":
        return <FontAwesomeIcon icon={faCrown} className="cws_vc_shortcode_icon_3x flaticon-crown" />;
      case "Pro":
        return <FontAwesomeIcon icon={faBolt} className="cws_vc_shortcode_icon_3x flaticon-speed" />;
      default:
        return null;
    }
  };

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const packageDetails = {
    "For Startups": "₹5000/-",
    ULTRA: "₹20000/-",
    Pro: "₹11000/-",
  };
  return (
    <>
     
     <ToastContainer />
     <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>
            <div className="pricing-title-wrapper">
              <span className="pricing-icon">
                {selectedPackage === "For Startups" && (
                  <FontAwesomeIcon
                    icon={faCube}
                    className="cws_vc_shortcode_icon_3x flaticon-cube"
                  />
                )}
                {selectedPackage === "ULTRA" && (
                  <FontAwesomeIcon
                    icon={faCrown}
                    className="cws_vc_shortcode_icon_3x flaticon-crown"
                  />
                )}
                {selectedPackage === "Pro" && (
                  <FontAwesomeIcon
                    icon={faBolt}
                    className="cws_vc_shortcode_icon_3x flaticon-speed"
                  />
                )}
              </span>
              <span className="pricing-title">{selectedPackage}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPackage && (
            <div className="package-details">
              <h2>{packageDetails[selectedPackage]}</h2>
              {/* Add other package details here */}
              <p>Unlock the {packageDetails[selectedPackage]}
 Package: Share Details, We'll Connect! </p>
           
            </div>
          )}
        <form className="mt-4" onSubmit={handleSubmit}  >
                    <div className="row">
                      <div className="col-lg-6">
                      <div className={`single-input-inner style-border ${formErrors.name ? "is-invalid" : ""}`}>
            <input
              type="text"
              placeholder="Your Name*"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              title="Name is required" // Add tooltip message
            />
            {formErrors.name && (
              <p className="error-message">{formErrors.name}</p>
            )}
          </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Email*"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            title="Valid email is required"
                          />
                          {formErrors.email && (
        <p className="error-message">{formErrors.email}</p>
      )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Phone*"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                            title="Phone number is required"
                          />
                           {formErrors.phone && (
        <p className="error-message">{formErrors.phone}</p>
      )}
            
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Subject*"
                            name="subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                            required
                            title="Subject is required"
                          />
                            {formErrors.subject && (
        <p className="error-message">{formErrors.subject}</p>
      )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea
                            placeholder="Message*"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                            title="Message is required"
                          />
                          {formErrors.message && (
        <p className="error-message">{formErrors.message}</p>
      )}
                        </div>
                      </div>
                      <div className="col-lg-12">
  <div className="single-input-inner style-border">
    {/* New dropdown field for pricing plans */}
    <select
      name="selectedPackage"
      value={selectedPackage}
      onChange={(e) => handlePackageSelect(e.target.value)}
      disabled={true} // Make the dropdown disabled so the user cannot change it
    >
      <option value="For Startups">For Startups</option>
      <option value="ULTRA">ULTRA</option>
      <option value="Pro">Pro</option>
    </select>
  </div>
</div>


                      <div className="col-12">
                      <button
            type="button"
            className="btn btn-black mt-0 w-100 border-radius-5"
            onClick={handleSubmit}
            disabled={Object.values(formErrors).some((error) => error !== "")}
          >
            Submit now
          </button>
                      </div>
                    </div>
                  </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
      {/* Pricing Area One start */}
      <div className='pricing-area bg-gray pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>Pricing plan</h6>
            <h2 className='title'>
              Best Plan to get our <span> Design Services</span>
            </h2>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              
              <div className='single-pricing-inner '>
              
      <div className="pricing-title-wrapper">
        <span className="pricing-icon">
          <FontAwesomeIcon icon={faCube} className="cws_vc_shortcode_icon_3x flaticon-cube" />
        </span>
        <span className="pricing-title">For Startups</span>
      </div>
    
                <h2 className='mb-3'>
                ₹5000/-
                {/* <sub>/mo</sub> */}
                </h2>
                <h3>For Startups</h3>
                <ul>
                  <li>
                    <FaCheck />
                    Logo Designing 1 Sample
                  </li>
                  <li>
                    <FaCheck />
                    Visiting Card
                  </li>
                  <li>
                    <FaCheck />
                    Letter Head
                  </li>
                  <li>
                    <FaCheck />
                    3 Day Delivery
                  </li>
                  <li className='hide'>
                    <FaCheck />
                    2 Revisions
                  </li>
                  {/* <li className='hide'>
                    <FaCheck />
                    Video &amp; Animation Production{" "}
                  </li> */}
                </ul>
                <a
                  className='btn btn-black border-radius border-radius-0 w-100'
                 
                  data-toggle="modal" data-target="#exampleModalCenter"
                  onClick={() => handlePackageSelect("For Startups")}
                  
                >
                  Buy Now
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-pricing-inner style-3 price-active'>
              <div className="pricing-title-wrapper" style={{color: '#fff'}}>
      <span className="pricing-icon" style={{color: '#ffe27a'}}>
        <FontAwesomeIcon icon={faCrown} className="cws_vc_shortcode_icon_3x flaticon-crown" />
      </span>
      <span className="pricing-title">Ultra</span>
    </div>
                <h2 className='mb-3'>
                ₹20000/-
                </h2>
                <h3 style={{color: "#fff"}}>ULTRA</h3>
                <ul>
                  <li>
                    <FaCheck />
                    Logo Designing 4 Samples + Open File
                  </li>
                  <li>
                    <FaCheck />
                    Visiting Card
                  </li>
                  <li>
                    <FaCheck />
                    Letter Head
                  </li>
                  <li>
                    <FaCheck />
                    Single Page Website
                  </li>
                  <li className='hide'>
                    <FaCheck />
                    30 Days Delivery
                  </li>
               
                </ul>
                <a
                  className='btn btn-black border-radius border-radius-0 w-100'
                  onClick={() => handlePackageSelect("ULTRA")}
                >
                  Buy Now
                </a>
                <p style={{ textAlign: 'center', paddingTop: '20px' , color: '#fff' }}>*Note: Hosting &amp; Domain Not Included </p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-pricing-inner '>
              <div className="pricing-title-wrapper">
      <span className="pricing-icon">
        <FontAwesomeIcon icon={faBolt} className="cws_vc_shortcode_icon_3x flaticon-speed"  />
      </span>
      <span className="pricing-title">Pro</span>
    </div>
                <h2 className='mb-3'>
                ₹11000/-
                </h2>
                <h3>Pro Plan</h3>
                <ul>
                  <li>
                    <FaCheck />
                    Logo Designing 2 Samples + Open File
                  </li>
                  <li>
                    <FaCheck />
                    Visiting Card
                  </li>
                  <li>
                    <FaCheck />
                    Letter Head
                  </li>
                  <li>
                    <FaCheck />
                    Poster Designing 4 No's
                  </li>
                  <li className='hide'>
                    <FaCheck />
                    8 Days Delivery
                  </li>
                
                </ul>
                <a
                  className='btn btn-black border-radius border-radius-0 w-100'
                  onClick={() => handlePackageSelect("Pro")}
                >
                  Buy Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing Area One start */}
    </>
  );
};

export default PricingAreaOne;
