import React, {  useEffect, useRef } from "react";
import Breadcrumb from "../components/Breadcrumb";
import TermsAndConditions from "../components/TermsAndConditions";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import LoadingBar from 'react-top-loading-bar'; // Import the LoadingBar component

const Contact = () => {
  const loadingBarRef = useRef(null); // Create a reference for the loading bar
  useEffect(() => {
    // Optional: Simulate a loading process when the Service component mounts
    loadingBarRef.current.continuousStart(); // Start the loading bar
    setTimeout(() => {
      loadingBarRef.current.complete(); // Complete the loading bar after a delay (simulating loading)
    }, 2000); // Change the delay time as needed
  }, []);
  return (
    <>
     <LoadingBar color="#1F5ABC" ref={loadingBarRef} /> 
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Terms"} />

      {/* Contact Main */}
      <TermsAndConditions />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Contact;
