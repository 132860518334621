import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaRegUser, FaRegFolderOpen, FaQuoteRight, FaCalendarAlt, FaPinterest, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AdSenseAd from '../pages/AdSenseAd';

const PortfolioDetailArea = () => {
  const { slug } = useParams();
  const [portfolio, setPortfolio] = useState(null);
  const [postDate, setPostDate] = useState(null);
  const [author, setAuthor] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    if (slug) {
      axios
        .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio?_embed&slug=${slug}`)
        .then((response) => {
          console.log('API response:', response.data);
          if (response.data.length > 0) {
            setPortfolio(response.data[0]);
          }
        })
        .catch((error) => {
          console.error('Error fetching portfolio data:', error);
        });
          // Fetch recent portfolio posts
    axios
    .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio?_embed&per_page=3`)
    .then((response) => {
      console.log('Recent portfolio posts response:', response.data);
      setRecentPosts(response.data);
    })
    .catch((error) => {
      console.error('Error fetching recent portfolio posts:', error);
    });
    }
  }, [slug]);

  
  const fetchAuthor = (authorId) => {
    axios
      .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/users/${authorId}`)
      .then((response) => {
        console.log("Author response:", response.data);
        setAuthor(response.data);
      })
      .catch((error) => {
        console.error("Error fetching author data:", error);
      });
  };

  const [categories, setCategories] = useState([]);

useEffect(() => {
  fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio_category`)
    .then(response => response.json())
    .then(data => setCategories(data))
    .catch(error => console.error('Error fetching categories:', error));
}, []);


  useEffect(() => {
    if (portfolio) {
      // Get the date from the fetched portfolio post
      const postDate = new Date(portfolio.date);

      // Format the date as "DD MMM"
      const options = { day: 'numeric', month: 'short' };
      const formattedDate = postDate.toLocaleDateString('en-US', options).toUpperCase();

      // Set the formatted date in the state
      setPostDate(formattedDate);
    }
  }, [portfolio]);

  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/portfolio_tag`)
      .then(response => response.json())
      .then(data => setTags(data))
      .catch(error => console.error('Error fetching tags:', error));
  }, []);

  return (


    <div className="blog-area pd-top-120 pd-bottom-120" data-aos="zoom-in">
      
    <Helmet>
    <title>PortFolio | Planet Solutions
        </title>
          <meta name="description" content="'Planet Solutions' represents considerable authority in Website Development, Graphic Design, and Social Media Solutions"/>
<link rel='canonical' href={`${window.location.origin}/${slug}`} />
</Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {portfolio && (
              <div className="blog-details-page-content">
                <div className="single-blog-inner">
                  <div className="thumb">
                    {portfolio._embedded['wp:featuredmedia'] && (
                      <img
                        src={portfolio._embedded['wp:featuredmedia'][0].source_url}
                        alt={portfolio.title.rendered}
                        className="img-fluid"
                        style={{ width: '100%' }}
                      />
                    )}
                    {postDate && <span className="date">{postDate}</span>}
                  </div>
                  <div className="details">
                    <ul className="blog-meta">
                    <li>
                        <FaRegUser /> By {portfolio._embedded.author[0]?.name || 'Unknown Author'}
                      </li>
                      <li>
                        <FaRegFolderOpen /> {portfolio._embedded['wp:term'][0][0]?.name || 'Uncategorized'}
                      </li>
                    </ul>
                    <h3 className="blog-title">{portfolio.title.rendered}</h3>
                    <div dangerouslySetInnerHTML={{ __html: portfolio.content.rendered }} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-4 col-12">
          <div className="td-sidebar">
        
   <div className='widget widget_author text-center'>
   <div className='thumb'>
     <img src='/assets/img/icon.png' alt='planet-solutions-icon' />
   </div>
   <div className='details'>
     <h5>Planet Solutions</h5>
     
     <ul className="social-media">
     <li>
       <a href="https://www.facebook.com/PlanetSolutionsau?mibextid=ZbWKwL">
         <FaFacebookF />
       </a>
     </li>
     <li>
       <a href="https://www.instagram.com/planetsolutionsau/">
         <FaInstagram />
       </a>
     </li>
     <li>
       <a href="https://www.pinterest.com.au/Planetsolutionsau/">
       <FaPinterest />
       </a>
     </li>
   </ul>
   </div>
 </div>
              {/* Other widgets */}
              <div className="widget widget_search">
                   {/* Vertical ads */}
      <h4 className='widget-title'>AD Area</h4>
      <AdSenseAd adClient="ca-pub-3971043314961130" adSlot="1576322118" />
                </div>
              <div className="widget widget-recent-post">
               <h4 className='widget-title'>Portfolio Archive</h4>
               <ul>
    {recentPosts.map(post => (
      <li key={post.id}>
        <div className='media'>
          <div className='media-left'>
            {post._embedded['wp:featuredmedia'] && (
              <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
            )}
          </div>
          <div className='media-body align-self-center'>
            <h6 className='title'>
              <Link to={`/portfolio/${post.slug}`}>{post.title.rendered}</Link>
            </h6>
            <div className='post-info'>
              <FaCalendarAlt />
              <span>{new Date(post.date).toLocaleDateString()}</span>
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
</div>
              <div className="widget widget_tag_cloud mb-0">
              <h4 className='widget-title'>Tags</h4>
      <div className='tagcloud'>
        {tags.map(tag => (
          <a key={tag.id} href={`/portfolio?tag=${tag.id}`}>
            {tag.name}
          </a>
        ))}
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailArea;
