import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCalendarAlt, FaRegComments, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";

const BlogAreaOne = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [commentCounts, setCommentCounts] = useState({});

  useEffect(() => {
    // Fetch blog posts
    axios
      .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&per_page=3`)
      .then((response) => {
        setBlogPosts(response.data);

        // Fetch comment counts for each post
        response.data.forEach((post) => {
          axios
            .get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/comments?post=${post.id}`)
            .then((commentResponse) => {
              // Count the comments associated with the current post
              const count = commentResponse.data.length;
              
              // Update the commentCounts state using the post's ID as the key
              setCommentCounts((prevCounts) => ({
                ...prevCounts,
                [post.id]: count,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching comments for post ${post.id}:`, error);
            });
        });
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);

  return (
    <>
      {/*===================== Blog Area One start =====================*/}
      <div className='blog-area pd-top-120 pd-bottom-90'>
        <div className='container '>
          <div className='section-title text-center'>
            <h4 className='sub-title'>Recent Posts</h4>
            <h2 className='title'>
              Our Latest <span>Blog Posts</span>
            </h2>
          </div>
          <div className='row'>
            {blogPosts.map((post) => (
              <div key={post.id} className='col-lg-4 col-md-6' data-aos="fade-up" data-aos-duration="900">
                <div className='single-blog-list'>
                  <div className='thumb'>
                    {post._embedded && post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0] && (
                      <img
                        src={post._embedded["wp:featuredmedia"][0].source_url}
                        alt='img'
                      />
                    )}
                  </div>
                  <div className='details'>
                    <p className='date mb-3'>
                      <FaCalendarAlt />
                      {post.date && new Date(post.date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                    <h3>
                      <Link to={`/blog-details/${post.slug}`}>
                        {post.title && post.title.rendered}
                      </Link>
                    </h3>
                    <div className='meta'>
                      <div className='row'>
                        <div className='col-6'>
                          <p>
                            <FaRegUser />
                            {post._embedded.author ? post._embedded.author[0].name : "Unknown Author"}
                          </p>
                        </div>
                        <div className='col-6 text-end'>
                          <p>
                            <FaRegComments />
                            {commentCounts[post.id] || 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* ===================== BlogAreaOne End =====================*/}
    </>
  );
};

export default BlogAreaOne;
