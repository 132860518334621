import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import PortfolioGallery from "../components/PortfolioGallery"
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";

const Gallery = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"PortFolio Gallery"} />

      {/* Contact Main */}
      <PortfolioGallery />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Gallery;
