import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';


const NotificationBanner = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    // Automatically trigger the notification after a delay
    const timer = setTimeout(() => {
      setShow(true);
    }, 3000); // Display for 3 seconds

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  return (
    <div className={`notification-banner ${show ? 'slide-down' : ''}`}>
      <Alert variant="info" onClose={handleClose} dismissible>
        This is a notification banner.
      </Alert>
    </div>
  );
};

export default NotificationBanner;
